module.exports = {
  staticHost: 'https://uc.weiwenjia.com',
  buildPath: '/web/',
  publicPath: '/web/',
  oosHost: 'https://uc-prod-assets.lixiaoyun.com',
  AppConf: {
    redirectHost: 'https://uc.weiwenjia.com',
    publicPath: '/web/index.html',
    wpsAppId: 'AK20220218LKRIWS',
    dingdingClientId: 'suitesdecbafquhdvc6aw',
    api: {
      faye: 'https://uc-faye.ikcrm.com/faye',
      crmFaye: 'https://pc-faye.ikcrm.com/faye',
      domain: 'https://uc.weiwenjia.com',
      versionDomain: 'https://wwj-static.ikcrm.com',
      pathPrefix: '/api',
    },
    lixiaoyunHost: "https://www.lixiaoyun.com",
    CRMHandoverHost: {
      platform: 'https://e.lixiaocrm.com',
      ik: 'https://e.ikcrm.com',
      lx: 'https://lxcrm.weiwenjia.com',
      '3e1dbaaa3c184bd5a33ab5fbf162462a': 'https://lxcrm.weiwenjia.com',
      dingding: 'https://dingtalk.e.ikcrm.com',
      dinglogin: 'https://www.ikcrm.com/users/sign_in'
    },
    jumpToEnterpriseWeChatAppToken: [
      ['2fc3b8d8828c85ca1cd5244af154c02c', 'https://e.lixiaocrm.com', 'CRM'],
      ['ac2a065602491b9d247ea6d1d8f5a557', 'https://jxc.lixiaocrm.com/dashboard', 'JXC'],
      ['2ba2a9bf5178445e974a4cf89241ddbb', 'https://lxcrm.weiwenjia.com', 'CRM'],
    ],
    jumpToWhere: {
      CRM: 'https://www.lixiaocrm.com/uc/wx/prod',
      JXC: 'https://www.lixiaocrm.com/uc/wx/prod',
    },
    jxcHost: {
      ik: 'https://e.ikjxc.com/users/auth'
    },
    appToken: {
      IK: 'a14cc8b00f84e64b438af540390531e4',
      QWCRM: '2fc3b8d8828c85ca1cd5244af154c02c',
      XLJQR: 'f559e06d58c086bdc5560de4712e1cfd',
      QWJXC: 'ac2a065602491b9d247ea6d1d8f5a557',
      DLJXC: '6a99a826235f75de223cf5184caa0cc8',
      CRM1688: 'cc5e5dbabc39440393ec32e9a0eaf60f',
      DINGDING: '60ff3d7fd00c672c52a780b283bd738e',
      FEISHU: "2ba2a9bf5178445e974a4cf89241ddbb",
      QIDIAN: "075fd3547ca24627a8552741513cc617",
    },
    clientId: 'cli_a3e8c3689879500c',
    productToken: [
      '3e1dbaaa3c184bd5a33ab5fbf162462a',
      'e2a24777f5084cb8bd88d00c5edb4577',
      '267b9c009e5b407397f3bf9c5a2d623c'
    ],
    templates: {
      importUser: 'https://uc-files.weiwenjia.com/file-1713419128907用户导入模版.xlsx'
    },
    dlOAuth:{
      domain:'https://open.weixin.qq.com/connect/oauth2/authorize',
      lxUrl:`https://lxcrm.weiwenjia.com/wx/home`,
      qwUrl: 'https://e.lixiaocrm.com/wx/home'
    }
  },
};
